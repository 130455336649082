@mixin mobile() {
  @media (max-width: 1023px) {
    @content;
  }
}

@mixin pc() {
  @media (min-width: 1024px) {
    @content;
  }
}

@mixin main-title {
  margin: 0;
  color: #16181A;
  font-size: pxToPCVW(30);
  font-weight: 400;
  line-height: 1;
}


@mixin sub-title {
  margin: 0;
  color: #16181A;
  font-size: pxToPCVW(16);
  font-weight: 400;
  line-height: 1;
  text-transform: uppercase;
}