@import "~@assets/styles/global.scss";
// @include pc() {

    .header {
        position: absolute;
        left: 0;
        right: 0;
        // justify-content: space-between;
        z-index: 2;
        background-color: #fff;
        box-shadow: 13px 9px 52px 5px #E2E8F5;


        .content {
            height: pxToPCVW(72);
            margin: 0 auto;
            display: flex;
            align-items: center;
            width: pxToPCVW($main-container-width);
        }

        .container {
            padding: 0;
            display: flex;
            align-items: center;
        }

        .logo {
            overflow: hidden;
            width: pxToPCVW(120);
            margin-right: pxToPCVW(126);
            cursor: pointer;

            img {
                width: 100%;
            }
        }

        .navigation {
            font-size: pxToPCVW(14);
            text-align: center;
            padding: 0;
            margin: 0 pxToPCVW(90) 0 0;
            list-style: none;

            &.active {
                a {
                    color: #0052D9;
                    border-bottom: 2px solid #0052D9;
                    font-weight: bold;
                }
            }

            a {
                color: #16181A;
                height: pxToPCVW(68);
                line-height: pxToPCVW(72);
                display: block;
                text-decoration: none;

                &:hover {
                    color: #0052D9;
                    border-bottom: 2px solid #0052D9;
                }
            }

        }
    }
// }

// @include mobile() {
//     .navigator {
//         color: #fff;
//         height: pxToMobileVW(20);
//         width: 100%;
//     }
// }