@import "~@assets/styles/global.scss";
.container {
  padding: pxToPCVW(96) 0 pxToPCVW(66);
  background: #fff;

  .title {
    @include main-title();
    text-align: center;
  }

  .sub_title {
    @include sub-title();
    text-align: center;
    margin-top: pxToPCVW(16);
  }

  .news_container {
    margin: 0 auto;
    margin-top: pxToPCVW(82);
    width: pxToPCVW($main-container-width);
    display: flex;
    align-items: center;
    justify-content: space-around;
  }
}

.news_item {
  width: pxToPCVW(380);

  .image {
    width: 100%;
    height: pxToPCVW(188);
    border-radius: pxToPCVW(4);
    overflow: hidden;
    img {
      width: 100%;
    }
  }

  .content {
    margin-top: pxToPCVW(20);
    padding-left: 2px;
  }

  .time {
    display: flex;
    align-items: center;

    .news {
      margin-bottom: pxToPCVW(12);
      width: pxToPCVW(24);
    }
    margin: 0;
    font-size: pxToPCVW(20);
    line-height: pxToPCVW(34);
    color: #000;
  }

  .desc {
    margin: 0;
    font-size: pxToPCVW(14);
    line-height: pxToPCVW(34);
    color: #495770;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
