@import "~@assets/styles/global.scss";
.business {
    position: relative;
    margin: 0 auto;
    margin-top: pxToPCVW(82);
    width: pxToPCVW($main-container-width);

    .title {
        @include main-title();
        text-align: center;
    }

    .sub_title {
        @include sub-title();
        text-align: center;
        margin-top: pxToPCVW(16);
    }

    .business_swiper {
        position: relative;
        margin-top: pxToPCVW(64);
    }

    .swiper_slide {
        font-size: pxToPCVW(40);
        text-align: center;
        width: pxToPCVW(280);
        height: pxToPCVW(432);
    }

    .swiper_btn {
        position: absolute;
        display: flex;
        top: 50%;
        width: pxToPCVW(40);
        height: pxToPCVW(40);
        font-size: pxToPCVW(16);
        font-weight: bold;
        color: #888;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        border-radius: 50%;
        border: 3px solid #fff;
        background-color: #E2E8F5;
        box-shadow:13px 9px 52px 5px #E2E8F5;
        z-index: 1;
    }

    .swiper_btn_pre {
        left: pxToPCVW(-36);
        transform: translateX(-100%) translateY(-50%);
    }

    .swiper_btn_next {
        right: pxToPCVW(-36);
        transform: translateX(100%) translateY(-50%);
    }
}

.business_item {
    position: relative;
    height: 100%;
    img {
        vertical-align: top;
        width: 100%;
    }

    .content {
        position: absolute;
        width: pxToPCVW(250);
        left: 50%;
        transform: translateX(-50%);
        padding: pxToPCVW(40) pxToPCVW(20);
        margin-top: pxToPCVW(-14);
        // background: #F5F7F9;
        border: 2px solid #fff;
        border-radius: 4px;
        box-sizing: border-box;
        background: linear-gradient(rgba(#F5F7F9,1), rgba(#F5F7F9,0.6)) ;
        z-index: 1;
        box-shadow:13px 9px 52px 5px #E2E8F5;
        // filter: blur(10px);

        .title {
            font-size: pxToPCVW(18);
            color: #16181A;
        }

        .desc {
            margin-top: pxToPCVW(30);
            font-size: pxToPCVW(14);
            color: #495770;
            line-height: pxToPCVW(30);
        }
    }
    .modification {
        position: absolute;
        width: pxToPCVW(150);
        height: pxToPCVW(16);
        left: 50%;
        bottom: 0;
        transform: translate(-50%, 100%);
        background-color: #0052D9;
        border-radius: 0 0 pxToPCVW(16) pxToPCVW(16);
        border: 2px solid #fff;
        // border-top: none;
    }

    .filter {
        position: absolute;
        transform: translateX(-50%) translateY(2px);
        bottom: 0;
        left: 50%;
        width: pxToPCVW(160);
        height: pxToPCVW(16);
        background-color: #0052D9;
        filter: blur(20px);
        z-index: 1;
        border-radius: 100%;
    }
}