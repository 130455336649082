
@function pxToPCVW($px) {
  $base_width: 1920;
  @return ($px / $base_width) * 100vw;
}

@function pxToMobileVW($px) {
  $base_width: 750;
  @return ($px / $base_width) * 100vw
}

