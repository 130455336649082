@import "~@assets/styles/global.scss";
.wrapper {
    margin-top: pxToPCVW(30);
    height: pxToPCVW(512);
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    perspective-origin: center -20%;
}

@keyframes rotateA {
    form {
        transform: rotateY(0deg)
    }

    to {
        transform: rotateY(360deg)
    }
}

.carousel {
    position: absolute;
    margin: auto;
    width: pxToPCVW(275);
    height: pxToPCVW(395);
    transform: rotateY(0deg);
    transform-style: preserve-3d;
    // overflow: hidden;
}




.carousel_item{
    position: absolute;
    // background: rgba($color: #000000, $alpha: 0.5);
    transform-style: preserve-3d;
}
