// 文字颜色
$main-text-color:#333333;
$normal-text-color:#666666;
$minor-text-color:#999999;
$placeholder-text-color:#BBBBBB;

// 背景色
$border-stroke-color:#DDDDDD;
$line-color:#E7E7E7;
$bg-color:#F4F4D6;


// 主题色
$theme-color:#272D36;
$theme-hover-color:#0794FE;

// 提醒色
$remind-right-color:#52C41A;
$remind-wrong-color:#FF633E;
$remind-warning-color:#F6BE53;


// font size
$main-title-size: 42;
$title-size: 32;
$middle-title-size: 24;
$small-title-size: 20;
$body-text-size: 14;
$auxiliary-words-size: 12;

// width
$main-container-width: 1200;

// .main-title{
//     font-size: 42px;
// }

// .title{
//     font-size: 32px;
// }


// .small-title{
//     font-size: 20px;
// }

// .body-text{
//     font-size: 14px;
// }

// .auxiliary-words{
//     font-size: 12px;
// }
