@import "~@assets/styles/global.scss";
.container {
  background-color: #272d36;
  .content {
    margin: 0 auto;
    padding-top: pxToPCVW(50);
    width: pxToPCVW($main-container-width);
  }
  .link_box {
    margin-bottom: pxToPCVW(52);
    display: flex;
    justify-content: space-between;
    color: $placeholder-text-color;
  }
  .link_title {
    margin-bottom: pxToPCVW(10);
    font-size: pxToPCVW($small-title-size);
    font-weight: 500;
    user-select: none;
    pointer-events: none;
  }

  .link_container {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
  }

  .link_item {
    display: block;
    margin-bottom: pxToPCVW(10);
    font-size: pxToPCVW(16);
    user-select: none;
    color: $placeholder-text-color;
    text-decoration: none;
    // pointer-events: none;
    cursor: pointer;
    &:last-child {
      margin-bottom: 0;
    }
    &:hover {
      color: #fff;
    }
  }
  .qrcode_box {
    display: flex;
  }
  .qrcode {
    margin-left: 32px;
    img {
      vertical-align: middle;
      width: pxToPCVW(104);
      height: pxToPCVW(104);
    }
    .qrcode_tip {
      text-align: center;
      font-size: pxToPCVW($auxiliary-words-size);
      margin: pxToPCVW(10) 0 0;
    }
  }
  .line {
    height: 1px;
    width: 100%;
    left: 0;
    background: #2B3240;
    transform: scale(1, 0.5);
  }
  .copy {
    text-align: center;
    font-size: pxToPCVW($auxiliary-words-size);
    color: $placeholder-text-color;
    height: pxToPCVW(44);
    line-height: pxToPCVW(44);
  }
}
