@import "~@assets/styles/global.scss";
.home {
    .swiper_container_home,.swiper_slide {
        width: 100%;
    }
    .banner {
        position: relative;
        display: flex;
        align-items: center;
        width: pxToPCVW(1920);
        height: pxToPCVW(1080);
        margin-top: pxToPCVW(-72);
        background: url('~@assets/images/Home/kv.png');
        background-size: cover;
        // box-shadow: 13px 9px 52px 5px #E2E8F5;

        .content {
            padding-left: pxToPCVW(344);
            .main_title {
                margin-bottom: 0;
                font-size: pxToPCVW(50);
                font-weight: 400;
                color: #16181A;
                line-height: pxToPCVW(74);
            }
            .sub_title {
                margin-bottom: 0;
                margin-top: pxToPCVW(20);
                font-size: pxToPCVW(18);
                font-weight: 400;
                color: #3D485D;
                line-height: pxToPCVW(40);
            }

            .banner_detail {
                width: pxToPCVW(138);
                height: pxToPCVW(44);
                margin-top: pxToPCVW(24);
                background: #0052D9;
                box-shadow: 2px 6px 21px 3px rgba(155,172,186,0.3900);
                font-size: pxToPCVW(16);
            }

            :global {
                .ant-btn-primary {
                    padding: pxToPCVW(4) pxToPCVW(15);
                    background-color: #0052D9 !important;
                }
            } 
        }
    }


    .banner1 {
        position: relative;
        display: flex;
        align-items: center;
        width: pxToPCVW(1920);
        height: pxToPCVW(1080);
        margin-top: pxToPCVW(-72);
        background: url('~@assets/images/Home/kv1.png');
        background-size: cover;
        // box-shadow: 13px 9px 52px 5px #E2E8F5;

        .content {
            padding-left: pxToPCVW(344);
            .main_title {
                margin-bottom: 0;
                font-size: pxToPCVW(50);
                font-weight: 400;
                color: #16181A;
                line-height: pxToPCVW(74);
            }
            .sub_title {
                margin-bottom: 0;
                margin-top: pxToPCVW(20);
                font-size: pxToPCVW(18);
                font-weight: 400;
                color: #3D485D;
                line-height: pxToPCVW(40);
            }

            .banner_detail {
                width: pxToPCVW(138);
                height: pxToPCVW(44);
                margin-top: pxToPCVW(24);
                background: #0052D9;
                box-shadow: 2px 6px 21px 3px rgba(155,172,186,0.3900);
                font-size: pxToPCVW(16);
            }

            :global {
                .ant-btn-primary {
                    padding: pxToPCVW(4) pxToPCVW(15);
                    background-color: #0052D9 !important;
                }
            } 
        }
    }
}