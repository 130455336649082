@import "~@assets/styles/global.scss";
.advantage_item {
    padding: pxToPCVW(36) pxToPCVW(34);
    width: pxToPCVW(326);
    height: pxToPCVW(194);
    border: 2px solid #FEFEFE;
    background: linear-gradient(0deg, #FEFEFE 0%, #F4F6F8 100%);
    box-shadow: 8px 6px 34px 0px rgba(223,229,242,0.9300);
    border-radius: 4px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    cursor: pointer;
    transition: all .3s;

    .advantage_title {
        margin: 0;
        font-size: pxToPCVW(18);
        font-weight: 400;
    }

    .desc {
        margin-top: pxToPCVW(26);
        list-style: none;
        padding: 0;
    }

    .desc_item {
        font-size: pxToPCVW(15);
        color: #495770;
    }

    .right {
        flex: 1;
        text-align: center;
        img {
            margin-top: pxToPCVW(26);
        }
    }

    &:nth-child(1) {
        img { width: pxToPCVW(60); }
    }

    &:hover {
        transform: scale(1.1);
        // box-shadow: 8px 6px 14px 0px rgba(223,229,242,0.9300);
    }
}

.container {
    padding: pxToPCVW(110) 0 pxToPCVW(124);
    height: pxToPCVW(580);
    background: #fff;

    .title {
        @include main-title();
        text-align: center;
    }

    .sub_title {
        @include sub-title();
        margin-top: pxToPCVW(16);
        text-align: center;
    }

    .advantage {
        display: flex;
        justify-content: space-around;
        margin: 0 auto;
        margin-top: pxToPCVW(92);
        width: pxToPCVW($main-container-width);
    }
}