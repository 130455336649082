@import "~@assets/styles/global.scss";
.zocenet_divider {
    position: relative;
    margin: pxToPCVW(24) 0;
    height: 1px;
    width: 100%;
    .zocenet_divider__text {
        position: absolute;
        background-color: #fff;
        font-weight: 500;
        color: #333;
        font-size: pxToPCVW(14);
        padding: 0 pxToPCVW(20);
        z-index: 1;
    }

    .zocenet_divider__left {
        padding-left: 0;
        left: 0;
        transform: translateY(-50%);
    }
    .zocenet_divider__center {
        left: 50%;
        transform: translate(-50%, -50%);
    }
    .zocenet_divider__right {
        padding-right: 0;
        right: 0;
        transform: translateY(-50%);
    }
}