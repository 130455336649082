@import "~@assets/styles/global.scss";
.container {
  position: relative;
  height: pxToPCVW(72);
  .wrapper {
    position: relative;
    align-items: center;
    z-index: 99;
  }
}


.drawer{
  position: absolute;
  width: 100vw;
  top: pxToPCVW(72);
  left: 0;
  color: #16181A;
  height: pxToPCVW(328);
  z-index: 1;
  background-color: #fff;
  box-shadow: 13px 9px 52px 5px #E2E8F5;


  .drawer_content {
    display: flex;
    padding: pxToPCVW(40) 0;
    width: pxToPCVW($main-container-width);
    margin: 0 auto;
    height: 100%;
    box-sizing: border-box;
  }

  // &:hover{
  //   height: pxToPCVW(520);
  // }
  &_left{
    position: relative;
    width: pxToPCVW(300);
    padding: 0 pxToPCVW(20);
    box-sizing: border-box;
    img{
      width:pxToPCVW(50);
      height: pxToPCVW(50);
    }
    p{
      // margin:pxToPCVW(30) 0 pxToPCVW(20);
      font-size: pxToPCVW($middle-title-size);
    }
    div{
      width: pxToPCVW(260);
      font-size: pxToPCVW($auxiliary-words-size);

    }
    .drawer_split_line {
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      width: 1px;
      background: #fff;
      transform: scaleX(0.5);
    }
  }
  &_right{
    flex: 1;
    // padding-left: pxToPCVW(20);
    // display: flex;
    &_item{
      color: #16181A;
      display: inline-block;
      padding: pxToPCVW(12) pxToPCVW(20); 
      // border:1px dotted #fff;
      width:pxToPCVW(280);
      // height: pxToPCVW(70);
      margin-left:pxToPCVW(20); 
      box-sizing: border-box;
      cursor: pointer;
      &:hover {
        background-color: rgba($color: #E2E8F5, $alpha: 0.5);
        color: #000;
      }

      &.active {
        background-color: rgba($color: #E2E8F5, $alpha: 0.5);
        color: #0052D9;
      }
      // font-size: pxToPCVW($body-text-size);
      p{
        margin: 0;
        font-size: pxToPCVW(14);
      }
    }
    &_navItem{
      font-size: pxToPCVW($auxiliary-words-size);
      span{
        padding:0 pxToPCVW(5) ;
        border-right: 1px solid #fff;
        &:first-child{
          padding-left: 0;
        }
        &:last-child{
          border-right: none;
        }
      }

    }
  }
}
.drawer{
  transition:height .5s;
}
