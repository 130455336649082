@import "~@assets/styles/global.scss";
.container {
    padding-top: pxToPCVW(96);
    padding-bottom: pxToPCVW(66);
    background: #F5F7FA;

    .title {
        @include main-title();
        text-align: center;
    }

    .sub_title {
        @include sub-title();
        margin-top: pxToPCVW(16);
        text-align: center;
    }

    .qualification {
        padding: pxToPCVW(10);
        width: pxToPCVW(275);
        height: pxToPCVW(395);
        border: 2px solid #FFFFFF;
        background: linear-gradient(180deg, #F4F6F9 0%, #FDFDFD 100%);
        box-shadow: 16px 10px 50px 10px #E2E8F5;
        border-radius: 4px;
        box-sizing: border-box;

        img {
            width: 100%;
            height: pxToPCVW(348);
            object-fit: cover;
            // background: ;
        }

        h4 {
            margin: 0;
            margin-top: pxToPCVW(12);
            text-align: center;
            font-weight: 500;
            font-size: pxToPCVW(14);
            line-height: 1;
            color: #000;
        }
    }

}