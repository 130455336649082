@import "~@assets/styles/global.scss";
.container {
    margin-top: pxToPCVW(26);
    height: pxToPCVW(496);
    display: flex;
    align-items: center;
    background-image: url('~@assets/images/Home/profile_bg.png');
    .content {
        margin: 0 auto;
        width: pxToPCVW($main-container-width);
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .image {
        width: pxToPCVW(492);
        img {
            width: 100%;
        }
    }

    .info {
        width: pxToPCVW(620);
    }

    .title {
        @include main-title()
    }

    .sub_title {
        @include sub-title();
        margin-top: pxToPCVW(18)
    }

    .description {
        margin: 0;
        margin-top: pxToPCVW(40);
        font-size: pxToPCVW(20);
        line-height: 2;
        color: #16181A;
    }
}