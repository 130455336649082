@import "~@assets/styles/global.scss";

// @include pc(){
    .container {
        padding-top: pxToPCVW(100);
        box-sizing: border-box;
        background: #F5F7FA;

        .title{
          @include main-title();
          text-align: center;
        }
        .sub_title {
          @include sub-title();
          text-align: center;
          margin-top: pxToPCVW(16);
        }
        .content{
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            margin: pxToPCVW(60) auto;
            width: pxToPCVW($main-container-width);
            img{
              margin-bottom: pxToPCVW(62);
              margin-right: pxToPCVW(20);
              width: pxToPCVW(182);
              &:nth-child(5n) {
                margin-right: 0;
              }
            }
        }
    
    }

// }
// @include mobile{

// }